
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rundp-user-info',
  standalone: true,
  imports: [MatButtonModule, TranslateModule],
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent {
  @Input({ required: true })
  firstName = 'Arnold';

  @Input({ required: true })
  lastName = 'Thomas';

  @Input()
  imageUrl = '';

  @Output()
  logout = new EventEmitter();
}
