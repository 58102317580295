
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {RouterLink, RouterLinkActive} from '@angular/router';
// import {LanguageModule} from "../language/language.module";
import {TranslateModule} from "@ngx-translate/core";
import {Links} from '@rundp/api';

@Component({
  selector: 'rundp-footer',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, TranslateModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {

  /**
   * Links that should be displayed in the footer.
   * @default defaultLinks - A default array of links if not provided.
   */
  @Input()
  links: Links[] = [];

  /**
   * Version to display in the footer.
   * @required
   */
  @Input({ required: true })
  version: string  | undefined;

  /**
   * The build number to display in the footer.
   * @required
   */
  @Input({ required: true })
  build: string  | undefined;
}
