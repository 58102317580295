/* libs/rundp-components/src/lib/user-info/user-info.component.scss */
:root {
  --primary-50: #fef3e0;
  --primary-100: #fddfb2;
  --primary-200: #fccb80;
  --primary-300: #fab64d;
  --primary-400: #faa627;
  --primary-500: #f99702;
  --primary-600: #f58b01;
  --primary-700: #ef7c00;
  --primary-800: #e96c00;
  --primary-900: #e05200;
  --primary-A100: #fff;
  --primary-A200: #ffe2d8;
  --primary-A400: #ffbda5;
  --primary-A700: #ffaa8b;
  --primary-contrast-50: #000;
  --primary-contrast-100: #000;
  --primary-contrast-200: #000;
  --primary-contrast-300: #fff;
  --primary-contrast-400: #fff;
  --primary-contrast-500: #fff;
  --primary-contrast-600: #fff;
  --primary-contrast-700: #fff;
  --primary-contrast-800: #fff;
  --primary-contrast-900: #fff;
  --primary-contrast-A100: #000;
  --primary-contrast-A200: #000;
  --primary-contrast-A400: #000;
  --primary-contrast-A700: #000;
  --accent-50: #fafafa;
  --accent-100: #f5f5f5;
  --accent-200: #ededed;
  --accent-300: #dfdfdf;
  --accent-400: #bcbcbc;
  --accent-500: #9d9d9d;
  --accent-600: #747474;
  --accent-700: #606060;
  --accent-800: #414141;
  --accent-900: #202020;
  --accent-A100: #fff;
  --accent-A200: #f2f4ff;
  --accent-A400: #bfc9ff;
  --accent-A700: #a6b4ff;
  --accent-contrast-50: #000;
  --accent-contrast-100: #000;
  --accent-contrast-200: #000;
  --accent-contrast-300: #fff;
  --accent-contrast-400: #fff;
  --accent-contrast-500: #fff;
  --accent-contrast-600: #fff;
  --accent-contrast-700: #fff;
  --accent-contrast-800: #fff;
  --accent-contrast-900: #fff;
  --accent-contrast-A100: #000;
  --accent-contrast-A200: #000;
  --accent-contrast-A400: #000;
  --accent-contrast-A700: #000;
  --warn-50: #ffebee;
  --warn-100: #ffcdd2;
  --warn-200: #ef9a9a;
  --warn-300: #e57373;
  --warn-400: #ef5350;
  --warn-500: #f44336;
  --warn-600: #e53935;
  --warn-700: #d32f2f;
  --warn-800: #c62828;
  --warn-900: #b71c1c;
  --warn-A100: #ff8a80;
  --warn-A200: #ff5252;
  --warn-A400: #ff1744;
  --warn-A700: #d50000;
  --warn-contrast-50: #000;
  --warn-contrast-100: #000;
  --warn-contrast-200: #000;
  --warn-contrast-300: #000;
  --warn-contrast-400: #fff;
  --warn-contrast-500: #fff;
  --warn-contrast-600: #fff;
  --warn-contrast-700: #fff;
  --warn-contrast-800: #fff;
  --warn-contrast-900: #fff;
  --warn-contrast-A100: #000;
  --warn-contrast-A200: #fff;
  --warn-contrast-A400: #fff;
  --warn-contrast-A700: #fff;
}
:host {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.user-info {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column wrap;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 56px;
  overflow: hidden;
  text-align: end;
}
.username {
  font-size: 12px;
  text-overflow: ellipsis;
}
.logout {
  color: var(--primary-700);
  font-size: 10px;
}
.user-profile-picture {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  object-fit: contain;
}
.logout-btn {
  padding: 0;
  background: none;
  border: 0;
  color: var(--primary-700);
  font-size: 12px;
  cursor: pointer;
  transition: color 300ms ease-in-out;
}
.logout-btn:active {
  color: var(--primary-700);
}
.logout-btn:hover {
  color: var(--primary-400);
}
/*# sourceMappingURL=user-info.component.css.map */
