import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ContainerLayoutComponent } from './container-layout/container-layout.component';
import { MatIconRegistry } from '@angular/material/icon';
import { LoaderCounterStore, MetaActionsGroup } from '@rundp/api';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { debounceTime, filter, startWith } from 'rxjs';
import { PushPipe } from '@ngrx/component';
import { LoadingSpinnerComponent } from '@rundp/rundp-components';

@Component({
  standalone: true,
  imports: [RouterModule, ContainerLayoutComponent, LoadingSpinnerComponent, PushPipe],
  selector: 'rundp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'rundp';

  // Store with the current "loading" value - if higher than 0, a loading spinner is shown
  readonly loaderCounterStore = inject(LoaderCounterStore);

  readonly loading = toObservable(this.loaderCounterStore.count).pipe(startWith(0), debounceTime(250));

  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly _destroyRef = inject(DestroyRef);

  constructor(private matIconRegistry: MatIconRegistry) {
    // Switches MatIcons to Material Symbols
    this.matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  }

  ngOnInit(): void {
    // Dispatches an action to load the metadata (e.g. default columns for tables)
    this.store.dispatch(MetaActionsGroup.loadMeta());

    /* Needed to remove any URL fragments created by Keycloak.
     * Usually done by Keycloak.js itself, but due to a race-condition
     * with the Angular bootstrap, this has to be done manually */
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe(() => {
        const href = location.href
          .replace(/[&?#]code=[^&$]*/, '')
          .replace(/[&?#]scope=[^&$]*/, '')
          .replace(/[&?#]state=[^&$]*/, '')
          .replace(/[&?#]iss=[^&$]*/, '')
          .replace(/[&?#]session_state=[^&$]*/, '');

        history.replaceState(null, window.name, href);
      });
  }
}
