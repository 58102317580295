import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { LogoComponent } from '../logo/logo.component';
import { UserInfoComponent } from '../user-info/user-info.component';
import { SizeEnum } from '../common/types/size.enum';
import { isDropdownLink, NavbarItem } from '@rundp/api';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rundp-navbar',
  standalone: true,
  imports: [
    RouterLink,
    UserInfoComponent,
    DashboardComponent,
    LogoComponent,
    RouterLinkActive,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  private _navbarItems: NavbarItem[] = [];

  /**
   * Sets the size of the logo.
   * @internal - Should only be used by the navbar container
   * @required
   * @default 'small' - The default size is 'small' if not provided.
   */
  @Input({ required: true })
  size: SizeEnum = SizeEnum.SMALL;

  /**
   * Router link to navigate to when the logo is clicked
   * @required
   */
  @Input({ required: true })
  logoUrl!: string;

  /**
   * Sets the links that should be displayed in the navbar.
   * @param items - An array of items, of type: 'NavbarItem'.
   * @required - This input is required and must be provided.
   */
  @Input({ required: true })
  set navbarItems(items: NavbarItem[]) {
    this._getItemsDepth(items);
    this._navbarItems = items;
  }

  /**
   * The links that should be displayed in the navbar.
   */
  get navbarItems(): NavbarItem[] {
    return this._navbarItems;
  }

  /**
   * Guard function to check if the provided link is a dropdown link.
   * @internal
   */
  //  isDropdownLink = isDropdownLinkGuard;
  isDropdownLink = isDropdownLink;

  /**
   * Recursively checks if the provided array has a depth of greater then 3
   * If the depth is greater then 3, it logs to the console
   */
  private _getItemsDepth(items: NavbarItem[], currentDepth = 0) {
    if (currentDepth > 3) {
      console.warn('The maximum depth (3) of the nav-bar has been exceeded. Any links deeper then 3 are being ignored');

      return;
    }

    items.forEach((item) => {
      if (item && item.subLinks && Array.isArray(item.subLinks) && item.subLinks.length > 0) {
        this._getItemsDepth(item.subLinks, currentDepth + 1);
      }
    });
  }
}
