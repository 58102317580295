
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {SizeEnum} from '../common/types/size.enum';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rundp-logo',
  standalone: true,
  imports: [RouterLink, RouterLinkActive],
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  /**
   * Toggles between the small and large logo.
   * @required
   * @default 'large' - The default size is 'large' if not provided.
   */
  @Input({ required: true })
  size: SizeEnum = SizeEnum.LARGE;

  /**
   * The router link to navigate to when the logo is clicked
   * @required
   */
  @Input({required: true})
  routerHome = '/address';
}
