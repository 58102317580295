<div class="user-info">
  <div class="username">
    <span>{{ lastName }}, {{ firstName }}</span>
  </div>

  <button class="logout-btn" (click)="logout.emit()">{{'logout' | translate}}</button>
</div>

@if (!!imageUrl) {
  <img [src]="imageUrl" alt="user-profile-picture"
    class="user-profile-picture"/>
} @else {
  <svg class="user-profile-picture" fill="none" height="30" viewBox="0 0 30 30" width="30"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="m5 23.3594v1.6406h20v-1.6406c0-1.5104-1.1458-2.7344-3.4375-3.6719-2.2917-.9896-4.4792-1.4844-6.5625-1.4844s-4.2708.4948-6.5625 1.4844c-2.29167.9375-3.4375 2.1615-3.4375 3.6719zm13.5156-9.8438c.9896-.9896 1.4844-2.1614 1.4844-3.5156 0-1.35417-.4948-2.52604-1.4844-3.51562-.9896-.98959-2.1614-1.48438-3.5156-1.48438s-2.526.49479-3.5156 1.48438c-.9896.98958-1.4844 2.16145-1.4844 3.51562 0 1.3542.4948 2.526 1.4844 3.5156s2.1614 1.4844 3.5156 1.4844 2.526-.4948 3.5156-1.4844zm-18.5156-10.15622c0-.9375.3125-1.71876.9375-2.34376.67708-.677078 1.48438-1.01562 2.42188-1.01562h23.28122c.8854 0 1.6667.338542 2.3438 1.01562.6771.67709 1.0156 1.45834 1.0156 2.34376v23.28122c0 .8854-.3385 1.6667-1.0156 2.3438s-1.4584 1.0156-2.3438 1.0156h-23.28122c-.9375 0-1.7448-.3125-2.42188-.9375-.625-.6771-.9375-1.4844-.9375-2.4219z"
      fill="#202020"
      />
  </svg>
}

