import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, Input, OnInit, Signal, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { RESIZE_OPTION_BOX, ResizeObserverModule, ResizeObserverService } from '@ng-web-apis/resize-observer';
import { distinctUntilChanged, map, startWith } from 'rxjs';
import { NavbarComponent } from '../navbar/navbar.component';
import { UserInfoComponent } from '../user-info/user-info.component';
import { TranslateModule } from '@ngx-translate/core';
import { SizeEnum } from '../common/types/size.enum';
import { Store } from '@ngrx/store';
import { NavbarItem, selectUserFullname, UserInfo } from '@rundp/api';
import { PushPipe } from '@ngrx/component';

@Component({
  selector: 'rundp-navbar-container',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NavbarComponent, UserInfoComponent, ResizeObserverModule, TranslateModule, PushPipe],
  templateUrl: './navbar-container.component.html',
  styleUrls: ['./navbar-container.component.scss'],
  providers: [
    ResizeObserverService,
    {
      provide: RESIZE_OPTION_BOX,
      useValue: 'border-box',
    },
  ],
})
export class NavbarContainerComponent implements OnInit {
  private readonly _resizeObserver$ = inject(ResizeObserverService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly store = inject(Store);

  sizeEnumSmall: SizeEnum = SizeEnum.SMALL;

  //TODO: Remove once RundP choose a variant
  isDropdownVariant = false;

  /**
   * Items to display in the navbar
   * @required
   */
  @Input({ required: true })
  navbarItems: NavbarItem[] = [];

  /**
   * The router link to navigate to when the logo is clicked
   * @required
   */
  @Input({ required: true })
  logoUrl!: string;

  private readonly _breakpointObserver = inject(BreakpointObserver);

  /**
   * User information to display in the user info component
   * @required
   */
  @Input({ required: true })
  userInfo!: UserInfo;

  /**
   * @internal
   */
  readonly screenSize$ = this._breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).pipe(takeUntilDestroyed());

  /**
   * The inline size of the container
   * @internal
   */
  readonly containerInlineSize = toSignal(
    this._resizeObserver$.pipe(
      map((val) => val[0].borderBoxSize[0].inlineSize),
      startWith(0),
      distinctUntilChanged(),
      takeUntilDestroyed(this._destroyRef),
    ),
  ) as Signal<number>;

  /**
   * The inline size of the navbar
   * @internal
   */
  readonly navbarInlineSize = signal(0);

  /**
   * The remaining inline space, that can be used by the user info component
   * @internal
   */
  readonly remainingInlineSpace = computed(() => {
    const remaining = this.containerInlineSize() - this.navbarInlineSize();

    if (remaining < 0) {
      return 0;
    }

    return remaining;
  });

  /**
   * Checks if the screen size is small
   * @internal
   */
  readonly isSmallSize$ = this.screenSize$.pipe(map((result) => (result.matches ? SizeEnum.LARGE : SizeEnum.SMALL)));

  ngOnInit() {
    this.store
      .select(selectUserFullname)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((userInfo: UserInfo) => {
        this.userInfo.firstName = userInfo.firstName;
        this.userInfo.lastName = userInfo.lastName;
      });
  }

  /**
   * Event handler for resize events on the navbar
   * @param event Resize event
   * @internal
   */
  onNavbarResize(event: ResizeObserverEntry[]) {
    this.navbarInlineSize.set(event[0].borderBoxSize[0].inlineSize);
  }
}
