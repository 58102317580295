<nav>
  <rundp-logo [size]="size" [routerHome]="logoUrl"></rundp-logo>

  <ul>
    <li>
      <rundp-dashboard></rundp-dashboard>
    </li>
    @for (link of navbarItems; track link) {
      <li class="nav-item" data-testid="menu" routerLinkActive="active">
        @if (!isDropdownLink(link)) {
          <button mat-button class="nav-button nav-bar-button" [routerLink]="link.href">
            {{ link.displayName }}
          </button>
        } @else {
          <button mat-button class="nav-button nav-bar-button" [matMenuTriggerFor]="submenu">
            {{ link.displayName }}
            <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #submenu="matMenu">
            @for (sublink of link.subLinks; track sublink) {
              <button
                mat-menu-item
                class="nav-bar-button"
                [matMenuTriggerFor]="isDropdownLink(sublink) ? subsubmenu : null"
                [routerLink]="!isDropdownLink(sublink) ? sublink.href : null"
                routerLinkActive="active"
                #trigger="matMenuTrigger"
              >
                {{ sublink.displayName }}
                <mat-menu #subsubmenu="matMenu">
                  @for (subsublink of isDropdownLink(sublink) ? sublink.subLinks : []; track subsublink) {
                    <button
                      mat-menu-item
                      class="nav-bar-button"
                      [matMenuTriggerFor]="isDropdownLink(subsublink) ? subsubsubmenu : null"
                      [routerLink]="!isDropdownLink(subsublink) ? subsublink.href : null"
                      routerLinkActive="active"
                    >
                      {{ subsublink.displayName }}
                      <mat-menu #subsubsubmenu="matMenu">
                        @for (subsubsublink of isDropdownLink(subsublink) ? subsublink.subLinks : []; track subsubsublink) {
                          <button
                            mat-menu-item
                            class="nav-bar-button"
                            [routerLink]="!isDropdownLink(subsubsublink) ? subsubsublink.href : null"
                            routerLinkActive="active"
                          >
                            {{ subsubsublink.displayName }}
                          </button>
                        }
                      </mat-menu>
                    </button>
                  }
                </mat-menu>
              </button>
            }
          </mat-menu>
        }
      </li>
    }
  </ul>
</nav>
