@if (loading) {
  <div class="loading-container">
    <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
      <!-- Grauer Kreis -->
      <circle cx="50" cy="50" r="35" fill="#c5c6c6" />

      <!-- Roter 1/4-Kreis mit Schatten -->
      <defs>
        <!-- Definition für den Schatten -->
        <filter id="shadow" x="0" y="0" width="150%" height="150%">
          <feOffset result="offOut" in="SourceAlpha" dx="5" dy="5" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="3" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </defs>

      <!-- Roter 1/4-Kreis -->
      <path d="M50,50 L50,10 A40,40 0 0,0 10,50 Z" fill="#ef7c01" filter="url(#shadow)">
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          values="0 50 50; 360 50 50; 360 50 50"
          keyTimes="0; 0.7; 1"
          dur="2s"
          repeatCount="indefinite"
          calcMode="spline"
          keySplines="0.42 0 0.28 1;0 0 0 0"
        />
      </path>
    </svg>

    <span class="spinner-text">{{ "waitingForResponse" | translate }}</span>
  </div>
}
