import { Route } from '@angular/router';
import { AuthGuard } from '@rundp/api';
import { addressResolver } from './addresses/address.resolver';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'addresses',
    pathMatch: 'full',
  },
  {
    path: 'addresses',
    loadComponent: () => import('./addresses/addresses-overview/addresses-overview.component').then((c) => c.AddressesOverviewComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'addresses/:addressesID',
    loadComponent: () => import('./addresses/address-subnav/address-subnav.component').then((c) => c.AddressSubnavComponent),
    canActivate: [AuthGuard],
    resolve: { address: addressResolver },
    loadChildren: () => import('./address.routes').then((m) => m.ADDRESS_CHILD_ROUTES),
  },
  {
    path: 'contract',
    canActivate: [AuthGuard],
    loadComponent: () => import('./contracts/contracts.component').then((c) => c.ContractsComponent),
  },
  {
    path: 'assetManagement',
    canActivate: [AuthGuard],
    loadComponent: () => import('./asset-management/asset-management.component').then((c) => c.AssetManagementComponent),
  },
  {
    path: 'calculation',
    loadChildren: () => import('./calculation.routes').then((m) => m.CALC_CHILD_ROUTES),
  },
  {
    path: 'administration',
    canActivate: [AuthGuard],
    loadComponent: () => import('./administration/administration.component').then((c) => c.AdministrationComponent),
  },
  // footer-routes todo review können theoretisch wege oder
  {
    path: 'news',
    loadComponent: () => import('./news/news.component').then((c) => c.NewsComponent),
  },
  {
    path: 'help',
    loadComponent: () => import('./help/help.component').then((c) => c.HelpComponent),
  },
  {
    path: 'contact',
    loadComponent: () => import('./contact/contact.component').then((c) => c.ContactComponent),
  },
  {
    path: 'imprint',
    loadComponent: () => import('./imprint/imprint.component').then((c) => c.ImprintComponent),
  },
  {
    path: 'data-privacy',
    loadComponent: () => import('./data-privacy/data-privacy.component').then((c) => c.DataPrivacyComponent),
  },
];
