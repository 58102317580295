import * as i0 from '@angular/core';
import { InjectionToken, inject, ElementRef, NgZone, Injectable, Inject, Directive, Attribute, NgModule } from '@angular/core';
import * as i1 from 'rxjs';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { WINDOW } from '@ng-web-apis/common';

const RESIZE_OPTION_BOX_DEFAULT = 'content-box';
const RESIZE_OPTION_BOX = new InjectionToken('Box model to observe changes', {
    providedIn: 'root',
    factory: () => RESIZE_OPTION_BOX_DEFAULT,
});

const RESIZE_OBSERVER_SUPPORT = new InjectionToken('Resize Observer API support', {
    providedIn: 'root',
    factory: () => !!inject(WINDOW).ResizeObserver,
});

class ResizeObserverService extends Observable {
    constructor({ nativeElement }, ngZone, support, box) {
        let observer;
        super(subscriber => {
            if (!support) {
                subscriber.error('ResizeObserver is not supported in your browser');
                return;
            }
            observer = new ResizeObserver(entries => {
                ngZone.run(() => {
                    subscriber.next(entries);
                });
            });
            observer.observe(nativeElement, { box });
            return () => {
                observer.disconnect();
            };
        });
        return this.pipe(share());
    }
}
ResizeObserverService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: ResizeObserverService, deps: [{ token: ElementRef }, { token: NgZone }, { token: RESIZE_OBSERVER_SUPPORT }, { token: RESIZE_OPTION_BOX }], target: i0.ɵɵFactoryTarget.Injectable });
ResizeObserverService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: ResizeObserverService });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: ResizeObserverService, decorators: [{
            type: Injectable
        }], ctorParameters: function () { return [{ type: i0.ElementRef, decorators: [{
                    type: Inject,
                    args: [ElementRef]
                }] }, { type: i0.NgZone, decorators: [{
                    type: Inject,
                    args: [NgZone]
                }] }, { type: undefined, decorators: [{
                    type: Inject,
                    args: [RESIZE_OBSERVER_SUPPORT]
                }] }, { type: undefined, decorators: [{
                    type: Inject,
                    args: [RESIZE_OPTION_BOX]
                }] }]; } });

/**
 * @deprecated View Engine legacy
 * TODO: use arrow function for `useFactory` and delete this exported function in future major release
 */
// TODO switch to Attribute once https://github.com/angular/angular/issues/36479 is fixed
function boxExtractor({ nativeElement, }) {
    const attribute = nativeElement.getAttribute('waResizeBox');
    return boxFactory(attribute);
}
/**
 * @deprecated View Engine legacy
 * TODO: use arrow function for `useFactory` and delete this exported function in future major release
 */
function boxFactory(box) {
    return box || RESIZE_OPTION_BOX_DEFAULT;
}
class ResizeObserverDirective {
    constructor(waResizeObserver, _box) {
        this.waResizeObserver = waResizeObserver;
    }
}
ResizeObserverDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: ResizeObserverDirective, deps: [{ token: ResizeObserverService }, { token: 'waResizeBox', attribute: true }], target: i0.ɵɵFactoryTarget.Directive });
ResizeObserverDirective.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "12.2.17", type: ResizeObserverDirective, selector: "[waResizeObserver]", outputs: { waResizeObserver: "waResizeObserver" }, providers: [
        ResizeObserverService,
        {
            provide: RESIZE_OPTION_BOX,
            deps: [ElementRef],
            useFactory: boxExtractor,
        },
    ], ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: ResizeObserverDirective, decorators: [{
            type: Directive,
            args: [{
                    selector: '[waResizeObserver]',
                    outputs: ['waResizeObserver'],
                    providers: [
                        ResizeObserverService,
                        {
                            provide: RESIZE_OPTION_BOX,
                            deps: [ElementRef],
                            useFactory: boxExtractor,
                        },
                    ],
                }]
        }], ctorParameters: function () { return [{ type: i1.Observable, decorators: [{
                    type: Inject,
                    args: [ResizeObserverService]
                }] }, { type: undefined, decorators: [{
                    type: Attribute,
                    args: ['waResizeBox']
                }] }]; } });

class ResizeObserverModule {
}
ResizeObserverModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: ResizeObserverModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
ResizeObserverModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: ResizeObserverModule, declarations: [ResizeObserverDirective], exports: [ResizeObserverDirective] });
ResizeObserverModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: ResizeObserverModule });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: ResizeObserverModule, decorators: [{
            type: NgModule,
            args: [{
                    declarations: [ResizeObserverDirective],
                    exports: [ResizeObserverDirective],
                }]
        }] });

/**
 * Public API Surface of @ng-web-apis/resize-observer
 */

/**
 * Generated bundle index. Do not edit.
 */

export { RESIZE_OBSERVER_SUPPORT, RESIZE_OPTION_BOX, RESIZE_OPTION_BOX_DEFAULT, ResizeObserverDirective, ResizeObserverModule, ResizeObserverService, boxExtractor, boxFactory };

