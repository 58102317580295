/* apps/rundp/src/app/container-layout/container-layout.component.scss */
:host {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.nav {
  padding: 0 32px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
}
.container {
  flex: 1 1 auto;
  padding-bottom: 8px;
  overflow: auto;
}
.footer {
  margin-top: auto;
  padding: 12px 32px;
}
/*# sourceMappingURL=container-layout.component.css.map */
