import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, isDevMode, LOCALE_ID } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import {
  addAcceptLanguageInterceptor,
  AddressEffects,
  addressFeature,
  addressFeatureKey,
  CalculationEffects,
  calculationFeature,
  calculationFeatureKey,
  loadingInterceptor,
  MetaEffects,
  metaFeature,
  metaFeatureKey,
  provideKeycloak,
  provideReduxErrorHandling,
  serverErrorInterceptor,
  statusInterceptor,
  UserEffects,
  userFeature,
  userFeatureKey,
} from '@rundp/api';
import { appRoutes } from './app.routes';
import { MatDialogModule } from '@angular/material/dialog';
import { provideTranslate } from '@rundp/rundp-components';

const providers = [
  provideAnimations(),
  provideTranslate(),
  provideKeycloak(),
  provideStore(
    {
      [addressFeatureKey]: addressFeature.reducer,
      [userFeatureKey]: userFeature.reducer,
      [metaFeatureKey]: metaFeature.reducer,
      [calculationFeatureKey]: calculationFeature.reducer,
      router: routerReducer,
    },
    {
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    },
  ),
  provideEffects([AddressEffects, MetaEffects, UserEffects, CalculationEffects]),
  provideStoreDevtools({
    logOnly: !isDevMode(),
  }),
  provideRouterStore({
    stateKey: 'router',
  }),
  provideReduxErrorHandling(),
  provideHttpClient(withInterceptors([serverErrorInterceptor, loadingInterceptor, statusInterceptor, addAcceptLanguageInterceptor])),
  provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
  { provide: LOCALE_ID, useValue: 'de-DE' },
  MatDialogModule,
];

export const appConfig: ApplicationConfig = { providers };
