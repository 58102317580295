<div class="center-container">
  <span>{{ "footer.company" | translate }}</span>
  <a href="https://www.rundp.de" target="_blank">www.rundp.de</a>
  <div class="version-container">
    <span>{{ "footer.version" | translate }} {{ version }}</span>
    <span>{{ "footer.build" | translate }} {{ build }}</span>
  </div>
</div>
<ul class="links" data-testid="links">
  @for (link of links; track $index) {
    <li>
      @if (link.href!.indexOf("http") === 0) {
        <a [href]="[link.href]" target="_blank">{{ link.displayName }}</a>
      } @else {
        <a [routerLink]="[link.href]" routerLinkActive="active">{{ link.displayName }}</a>
      }
    </li>
  }
</ul>
