<rundp-navbar-container [navbarItems]="navbarItems" logoUrl="/" [userInfo]="{ firstName: '', lastName: '' }" class="nav">
</rundp-navbar-container>

<div class="container">
  <ng-content></ng-content>
</div>

@if (selectUserFooter$ | async; as selectUserFooter) {
  <rundp-footer
    [links]="selectUserFooter.links"
    [version]="selectUserFooter.version"
    [build]="selectUserFooter.build"
    class="footer"
  ></rundp-footer>
}
