/* libs/rundp-components/src/lib/navbar/navbar.component.scss */
:root {
  --primary-50: #fef3e0;
  --primary-100: #fddfb2;
  --primary-200: #fccb80;
  --primary-300: #fab64d;
  --primary-400: #faa627;
  --primary-500: #f99702;
  --primary-600: #f58b01;
  --primary-700: #ef7c00;
  --primary-800: #e96c00;
  --primary-900: #e05200;
  --primary-A100: #fff;
  --primary-A200: #ffe2d8;
  --primary-A400: #ffbda5;
  --primary-A700: #ffaa8b;
  --primary-contrast-50: #000;
  --primary-contrast-100: #000;
  --primary-contrast-200: #000;
  --primary-contrast-300: #fff;
  --primary-contrast-400: #fff;
  --primary-contrast-500: #fff;
  --primary-contrast-600: #fff;
  --primary-contrast-700: #fff;
  --primary-contrast-800: #fff;
  --primary-contrast-900: #fff;
  --primary-contrast-A100: #000;
  --primary-contrast-A200: #000;
  --primary-contrast-A400: #000;
  --primary-contrast-A700: #000;
  --accent-50: #fafafa;
  --accent-100: #f5f5f5;
  --accent-200: #ededed;
  --accent-300: #dfdfdf;
  --accent-400: #bcbcbc;
  --accent-500: #9d9d9d;
  --accent-600: #747474;
  --accent-700: #606060;
  --accent-800: #414141;
  --accent-900: #202020;
  --accent-A100: #fff;
  --accent-A200: #f2f4ff;
  --accent-A400: #bfc9ff;
  --accent-A700: #a6b4ff;
  --accent-contrast-50: #000;
  --accent-contrast-100: #000;
  --accent-contrast-200: #000;
  --accent-contrast-300: #fff;
  --accent-contrast-400: #fff;
  --accent-contrast-500: #fff;
  --accent-contrast-600: #fff;
  --accent-contrast-700: #fff;
  --accent-contrast-800: #fff;
  --accent-contrast-900: #fff;
  --accent-contrast-A100: #000;
  --accent-contrast-A200: #000;
  --accent-contrast-A400: #000;
  --accent-contrast-A700: #000;
  --warn-50: #ffebee;
  --warn-100: #ffcdd2;
  --warn-200: #ef9a9a;
  --warn-300: #e57373;
  --warn-400: #ef5350;
  --warn-500: #f44336;
  --warn-600: #e53935;
  --warn-700: #d32f2f;
  --warn-800: #c62828;
  --warn-900: #b71c1c;
  --warn-A100: #ff8a80;
  --warn-A200: #ff5252;
  --warn-A400: #ff1744;
  --warn-A700: #d50000;
  --warn-contrast-50: #000;
  --warn-contrast-100: #000;
  --warn-contrast-200: #000;
  --warn-contrast-300: #000;
  --warn-contrast-400: #fff;
  --warn-contrast-500: #fff;
  --warn-contrast-600: #fff;
  --warn-contrast-700: #fff;
  --warn-contrast-800: #fff;
  --warn-contrast-900: #fff;
  --warn-contrast-A100: #000;
  --warn-contrast-A200: #fff;
  --warn-contrast-A400: #fff;
  --warn-contrast-A700: #fff;
}
nav {
  display: flex;
  align-items: center;
  height: 64px;
  background-color: #fff;
}
ul {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;
  list-style: none;
}
.nav-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 0;
  transition: all 300ms ease-in-out;
}
.nav-item .nav-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 16px;
}
.nav-item .nav-button > mat-icon {
  margin-bottom: 4px;
  font-size: 20px;
}
.nav-item a {
  color: var(--accent-900);
  text-decoration: none;
  transition: color 300ms ease-in-out;
}
.nav-item button {
  transition: color 300ms ease-in-out;
}
.nav-item a:hover {
  color: var(--primary-700);
}
.nav-item button:hover {
  background: none;
  color: var(--primary-700);
  --mat-mdc-button-persistent-ripple-color: transparent;
}
button.active {
  color: var(--primary-700);
  --mat-menu-item-icon-color: var(--primary-700);
}
.nav-item.active a,
.nav-item.active .nav-button,
.nav-item.active .menu-trigger-btn {
  color: var(--primary-700);
}
.nav-item.active::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: var(--primary-700);
}
.navbar-link:hover svg,
.navbar-link.active svg {
  fill: var(--primary-700);
}
.dropdown {
  fill: var(--accent-600);
}
/*# sourceMappingURL=navbar.component.css.map */
