import { importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpBackend, HttpClientModule, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { filter, map, Observable } from 'rxjs';

/**
 * Implement an own TranslateHttpLoader using HttpBackend instead of HttpClient
 * Needed to avoid a circular dependency regarding the HttpClient
 * Important: The HttpBackend doesn't use any interceptors!
 */
export class TranslateHttpLoader implements TranslateLoader {
  constructor(
    private httpHandler: HttpBackend,
    public prefix: string,
    public suffix: string,
  ) {}

  getTranslation(lang: string): Observable<unknown> {
    const httpRequest = new HttpRequest('GET', `${this.prefix}${lang}${this.suffix}`);

    return this.httpHandler.handle(httpRequest).pipe(
      // @ts-expect-error Due to the filter we know that it's a HttpResponse
      filter((httpEvent: HttpEvent<unknown>) => httpEvent instanceof HttpResponse),
      map((httpResponse: HttpResponse<unknown>) => httpResponse.body),
    );
  }
}

/**
 * Creates a new translateLoader using HttpBackend
 * @param httpHandler HttpBackend instance
 */
export function translateLoaderFactory(httpHandler: HttpBackend): TranslateLoader {
  return new TranslateHttpLoader(httpHandler, '/assets/i18n/', '.json');
}

/**
 * Creates a new translateLoader with a customized path for Cypress testing
 * @param httpHandler HttpBackend instance
 */
export function translateLoaderFactoryCypress(httpHandler: HttpBackend): TranslateLoader {
  return new TranslateHttpLoader(httpHandler, `./assets/i18n/`, '.json');
}

export function getTranslationModule(useCypressFactory = false) {
  return TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: useCypressFactory ? translateLoaderFactoryCypress : translateLoaderFactory,
      deps: [HttpBackend],
    },
    defaultLanguage: 'de',
  });
}

export const provideTranslate = () => {
  return makeEnvironmentProviders([importProvidersFrom(HttpClientModule), importProvidersFrom(getTranslationModule())]);
};
