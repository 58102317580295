<rundp-navbar
  [navbarItems]="navbarItems"
  [size]="(isSmallSize$ | ngrxPush) ?? sizeEnumSmall"
  (waResizeObserver)="onNavbarResize($event)"
  waResizeBox="border-box"
  [logoUrl]="logoUrl"
>
</rundp-navbar>
@if (!isDropdownVariant) {
  <rundp-user-info [firstName]="userInfo.firstName" [lastName]="userInfo.lastName" [imageUrl]="userInfo.imageUrl ?? ''"></rundp-user-info>
}
