import { ResolveFn } from '@angular/router';
import { AddressActions } from '@rundp/api';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';

/**
 * Resolves the address based on the provided route.
 *
 * @typedef {function} ResolveFn
 * @param {unknown} route - The route to be resolved.
 */
export const addressResolver: ResolveFn<unknown> = (route) => {
  const store = inject(Store);
  const addressID = route.paramMap.get('addressesID');

  if (addressID) {
    if (addressID !== 'new') {
      store.dispatch(AddressActions.loadAndSetSelectedAddress({ id: addressID }));
    } else {
      store.dispatch(AddressActions.unsetSelectedAddress());

      route.data = { editMode: true, createNew: true };
    }
  } else {
    // This should never happen as the corresponding route only gets activated when an ID is set
    throw new Error("Can't resolve address - no ID given");
  }
};
