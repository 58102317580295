import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { FooterComponent, NavbarContainerComponent } from '@rundp/rundp-components';
import { Store } from '@ngrx/store';
import { Footer, NavbarItem, selectUserFooter, selectUserNavbarItems } from '@rundp/api';
import { filter, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'rundp-container-layout',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NavbarContainerComponent, FooterComponent],
  templateUrl: './container-layout.component.html',
  styleUrls: ['./container-layout.component.scss'],
})
export class ContainerLayoutComponent {
  private readonly store = inject(Store);
  private readonly destroyRef = inject(DestroyRef);

  navbarItems: NavbarItem[] = [];

  selectUserFooter$: Observable<Footer> = this.store.select(selectUserFooter);

  constructor() {
    const selectUserNavbarItems$ = this.store.select(selectUserNavbarItems);

    selectUserNavbarItems$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((value) => !!value)
      )
      .subscribe((value) => {
        this.navbarItems = value;
      });
  }
}
