/* libs/rundp-components/src/lib/loading-spinner/loading-spinner.component.scss */
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
}
.loading-container .spinner-text {
  color: #fff;
}
/*# sourceMappingURL=loading-spinner.component.css.map */
